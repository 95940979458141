// For more info on endpoints, please check [ https://api.ristoapp.com.br/api-docs ]
export const SERVER_URL = process.env.SERVER_URL || 'https://api.ristoapp.com.br';

export const AUTH = `${SERVER_URL}/token/admin`;
export const REQUEST_RESET = `${SERVER_URL}/token/reset`;
export const VALIDATE_CODE = `${SERVER_URL}/token/reset/code`;
export const RESET_PASSWORD = `${SERVER_URL}/token/reset/new`;

export const STRIPE = `${SERVER_URL}/stripe`;

export const USER_PLAN_IMPORT_NEW = `${SERVER_URL}/import/newImport`;

export const USER = `${SERVER_URL}/user`;
export const USER_ID = (id) => `${USER}/${id}`;
export const USER_CITY = (id) => `${USER}/city/${id}`;

export const NEWS = `${SERVER_URL}/news`;
export const NEWS_ID = (id) => `${NEWS}/${id}`;

export const ESTABLISHMENT = `${SERVER_URL}/establishment`;
export const ESTABLISHMENT_ALL = `${SERVER_URL}/establishment/all`;
export const ESTABLISHMENT_ID = (id) => `${ESTABLISHMENT}/${id}`;
export const ESTABLISHMENT_OWNER = (id, userId) => `${ESTABLISHMENT}/${id}/${userId}`;
export const ESTABLISHMENT_CULINARY = (id) => `${ESTABLISHMENT}/${id}/culinaries/`;
export const ESTABLISHMENT_CATEGORY = (id) => `${ESTABLISHMENT}/${id}/categories/`;

export const CATEGORY = `${SERVER_URL}/category`;
export const CATEGORY_ID = (id) => `${CATEGORY}/${id}`;

export const TYPES = `${SERVER_URL}/type`;

export const CULINARY = `${SERVER_URL}/culinary`;
export const CULINARY_ID = (id) => `${CULINARY}/${id}`;

export const CONTACT = `${SERVER_URL}/contact`;
export const CONTACT_ID = (id) => `${CONTACT}/${id}`;

export const CONTACT_TYPE = `${SERVER_URL}/contactType`;
export const CONTACT_TYPE_ID = (id) => `${CONTACT_TYPE}/${id}`;

export const PLAN = `${SERVER_URL}/plan`;
export const PLAN_ID = (id) => `${PLAN}/${id}`;

export const USER_PLAN = `${SERVER_URL}/userPlan`;
export const USER_PLAN_ID = (id) => `${USER_PLAN}/${id}`;
export const USER_PLAN_IMPORT = `${USER_PLAN}/import`;
export const USER_PLAN_ADDDIAMONDS = `${USER_PLAN}/addDiamonds`;

export const VOUCHER = `${SERVER_URL}/voucher/all`;
export const VOUCHER_ID = (id) => `${SERVER_URL}/voucher/${id}`;
export const VOUCHER_ESTABLISHMENT_ID = (id) => `${VOUCHER}/establishment/${id}`;

export const VOUCHER_APPLICATION = `${SERVER_URL}/voucherApplication`;
export const VOUCHER_APPLICATION_ID = (id) => `${VOUCHER_APPLICATION}/${id}`;
export const VOUCHER_APPLICATION_ACCEPT = (id) => `${VOUCHER_APPLICATION}/${id}/accept`;
export const VOUCHER_APPLICATION_REJECT = (id) => `${VOUCHER_APPLICATION}/${id}/reject`;

export const FAQ = `${SERVER_URL}/faq`;
export const FAQ_ID = (id) => `${FAQ}/${id}`;
export const FAQ_UPVOTE = (id) => `${FAQ}/${id}/upvote`;
export const FAQ_DOWNVOTE = (id) => `${FAQ}/${id}/downvote`;

export const SUPPORTER = `${SERVER_URL}/supporter`;
export const SUPPORTER_ID = (id) => `${SUPPORTER}/${id}`;

export const LOG = `${SERVER_URL}/log`;
export const LOG_ID = (id) => `${LOG}/${id}`;

export const IMAGE = `${SERVER_URL}/image`;

export const STATS = `${SERVER_URL}/statistics`;

/** - [GET] SHOW CITIES */
export const CITIES = `${SERVER_URL}/city/`;

/**
 * - [GET] Shows one city
 * - [PUT] Add vendors to the city
 * - [DELETE] Remove vendors from the city
 *  */
export const CITY = (
  id,
  { includeUsers = false, includeVendors = false } = {},
) => `${CITIES}/${id}?includeUsers=${includeUsers}&includeVendors=${includeVendors}`;
/**
 * Alias for {@link CITY}
 */
export const CITY_VENDORS = (id) => `${CITIES}/${id}`;
export const CITY_USER = (cityId, userId) => `${CITIES}/${cityId}/${userId}`;

/** Parses into a URL with query params.
 *
 * Example:DROP TABLE
 * ```
 *  import { Endpoints } from '../constants';
 *
 *  const url = Endpoints.parse(Endpoints.USER, {name: 'Lucas', age: '21' });
 *  console.log(url); // {baseUrl}/user?name=Lucas&age=21
 * ```
 */
export function parse(endpoint, query) {
  query = query || {};
  try {
    let url = `${endpoint}?`;
    const queryInfo = [];
    Object.entries(query).forEach(([key, value]) => {
      queryInfo.push(`${key}=${value}`);
    });
    url += queryInfo.join('&');

    return url;
  } catch (err) {
    return endpoint;
  }
}
