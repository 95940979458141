import { useState } from 'react';
import PropTypes from 'prop-types';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {
  Button,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';

import {
  Edit as EditIcon,
  Check as CheckIcon,
  Close as CloseIcon,
} from '@mui/icons-material';

import {
  WeekDayPicker,
  PeriodPicker,
  CityPicker,
  CulinariesPicker,
  CategoriesPicker,
  TypesPicker,
} from './index';
import { useAuth } from '../../../hooks';

function EstablishmentTableRow({
  inputKey, title, error, value, onChangeHandle, editMode, inputType,
}) {
  const [readOnly, setReadOnly] = useState(!editMode);
  const { user } = useAuth();
  const toggleReadOnly = () => {
    setReadOnly(!readOnly);
  };

  const userCities = user.roleId === 2 ? user.cities : [];
  console.log(user.cities);
  const buttons = () => {
    const buttonRow = [];
    if (readOnly) {
      buttonRow.push(
        <Button
          key="editbutton"
          size="small"
          onClick={toggleReadOnly}
        >
          <EditIcon />
        </Button>,
      );
    } else {
      buttonRow.push(
        <Button
          key="cancelEdit"
          size="small"
          onClick={toggleReadOnly}
          sx={{ mr: 2 }}
        >
          <CloseIcon />
        </Button>,
      );
      buttonRow.push(
        <Button
          key="confirmEdit"
          size="small"
          onClick={toggleReadOnly}
        >
          <CheckIcon />
        </Button>,
      );
    }

    return buttonRow;
  };

  const cellSx = {
    backgroundColor: 'background.paper',
    border: 4,
    backgroundClip: 'padding-box',
    borderColor: 'transparent',
  };

  const modules = {
    toolbar: [
      [{ header: '1' }, { header: '2' }, { font: [] }],
      [{ size: [] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
      ['link', 'image', 'video'],
      ['clean'],
    ],
    clipboard: {
      matchVisual: false,
    },
  };

  const input = () => {
    if (inputType === 'rich-text') {
      return (
        <>
          <style>
            {`
              .ql-editor p {
                margin: 0;
                padding: 0;
                line-height: 1.5;
              }
            `}
          </style>
          <ReactQuill
            theme="snow"
            value={value || ''}
            onChange={(content, delta, source, editor) => onChangeHandle(editor.getHTML())}
            modules={modules}
            readOnly={readOnly}
          />
        </>
      );
    }
    switch (inputKey) {
      case 'morning':
      case 'afternoon':
      case 'night':
        value = parseInt(value, 10) || 0;
        return (
          <WeekDayPicker
            inputKey={inputKey}
            value={value}
            onChangeHandle={onChangeHandle}
            readOnly={readOnly}
          />
        );
      case 'availableDays':
        value = parseInt(value, 10) || 0;
        return (
          <WeekDayPicker
            inputKey={inputKey}
            value={value}
            onChangeHandle={onChangeHandle}
            readOnly={readOnly}
          />
        );
      case 'availablePeriods':
        value = parseInt(value, 10) || 0;
        return (
          <PeriodPicker
            inputKey={inputKey}
            value={value}
            onChangeHandle={onChangeHandle}
            readOnly={readOnly}
          />
        );
      case 'City':
        return (
          <CityPicker
            inputKey={inputKey}
            value={value}
            error={error}
            onChangeHandle={onChangeHandle}
            readOnly={readOnly}
            userCities={userCities}
            user={user}
          />
        );
      case 'Categories':
        return (
          <CategoriesPicker
            inputKey={inputKey}
            value={value}
            error={error}
            onChangeHandle={onChangeHandle}
            readOnly={readOnly}
          />
        );
      case 'Types':
        return (
          <TypesPicker
            inputKey={inputKey}
            value={value}
            error={error}
            onChangeHandle={onChangeHandle}
            readOnly={readOnly}
          />
        );
      case 'Culinaries':
        return (
          <CulinariesPicker
            inputKey={inputKey}
            value={value}
            error={error}
            onChangeHandle={onChangeHandle}
            readOnly={readOnly}
          />
        );
      default:
        return (
          <TextField
            required
            fullWidth
            hiddenLabel
            error={!!error}
            variant="standard"
            name={inputKey}
            id={inputKey}
            helperText={error}
            value={value || ''}
            onChange={(event) => onChangeHandle(event.target.value)}
            InputProps={{
              disableUnderline: true,
              readOnly,
            }}
          />
        );
    }
  };

  return (
    <TableRow>
      <TableCell sx={cellSx}><Typography variant="bodyTitle">{title}</Typography></TableCell>
      <TableCell colSpan={2} sx={{ ...cellSx }}>
        {input()}
      </TableCell>
      <TableCell sx={{ ...cellSx }} align="center">{buttons()}</TableCell>
    </TableRow>
  );
}

EstablishmentTableRow.propTypes = {
  inputKey: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  error: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
    PropTypes.array,
  ]).isRequired,
  onChangeHandle: PropTypes.func.isRequired,
  editMode: PropTypes.bool.isRequired,
  inputType: PropTypes.string,
};

EstablishmentTableRow.defaultProps = {
  inputType: 'text',
};

export default EstablishmentTableRow;
